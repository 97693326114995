
@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-Bold.eot');
    src: url('CooperHewitt-Bold.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-Bold.woff') format('woff'),
         url('CooperHewitt-Bold.svg') format('svg');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-BoldItalic.eot');
    src: url('CooperHewitt-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-BoldItalic.woff') format('woff'),
         url('CooperHewitt-BoldItalic.svg') format('svg');
    font-weight: bold;
    font-style: italic;
}


@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-Book.eot');
    src: url('CooperHewitt-Book.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-Book.woff') format('woff'),
         url('CooperHewitt-Book.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-BookItalic.eot');
    src: url('CooperHewitt-BookItalic.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-BookItalic.woff') format('woff'),
         url('CooperHewitt-BookItalic.svg') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-Heavy.eot');
    src: url('CooperHewitt-Heavy.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-Heavy.woff') format('woff'),
         url('CooperHewitt-Heavy.svg') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-HeavyItalic.eot');
    src: url('CooperHewitt-HeavyItalic.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-HeavyItalic.woff') format('woff'),
         url('CooperHewitt-HeavyItalic.svg') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-Light.eot');
    src: url('CooperHewitt-Light.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-Light.woff') format('woff'),
         url('CooperHewitt-Light.svg') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-LightItalic.eot');
    src: url('CooperHewitt-LightItalic.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-LightItalic.woff') format('woff'),
         url('CooperHewitt-LightItalic.svg') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-Medium.eot');
    src: url('CooperHewitt-Medium.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-Medium.woff') format('woff'),
         url('CooperHewitt-Medium.svg') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-MediumItalic.eot');
    src: url('CooperHewitt-MediumItalic.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-MediumItalic.woff') format('woff'),
         url('CooperHewitt-MediumItalic.svg') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-Semibold.eot');
    src: url('CooperHewitt-Semibold.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-Semibold.woff') format('woff'),
         url('CooperHewitt-Semibold.svg') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-SemiboldItalic.eot');
    src: url('CooperHewitt-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-SemiboldItalic.woff') format('woff'),
         url('CooperHewitt-SemiboldItalic.svg') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-Thin.eot');
    src: url('CooperHewitt-Thin.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-Thin.woff') format('woff'),
         url('CooperHewitt-Thin.svg') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('CooperHewitt-ThinItalic.eot');
    src: url('CooperHewitt-ThinItalic.eot?#iefix') format('embedded-opentype'),
         url('CooperHewitt-ThinItalic.woff') format('woff'),
         url('CooperHewitt-ThinItalic.svg') format('svg');
    font-weight: 100;
    font-style: italic;
}
