/* 
#0C0032
rgba(67, 36, 251, 0.8);
#190061
#240090
#3500D3
#282828 */

/* 
font-family: 'IBM Plex Sans', sans-serif;
font-family: 'PT Sans Narrow', sans-serif;
font-family: 'Noto Sans', sans-serif;
font-family: 'Inconsolata', monospace;
font-family: 'Karla', sans-serif;
font-family: 'Merriweather Sans', sans-serif;
font-family: 'Archivo Narrow', sans-serif;
font-family: 'Fira Sans Condensed', sans-serif;
font-family: 'Nunito Sans', sans-serif;
font-family: 'Source Sans Pro', sans-serif 
*/

@import url('https://fonts.googleapis.com/css?family=Archivo+Narrow:400,500,500i,600,700|Fira+Sans+Condensed:300,400,500,600,700|IBM+Plex+Sans:300,300i,400,500,600|Inconsolata:400,700|Karla:400,400i,700,700i|Merriweather+Sans:400,700,700i|Noto+Sans|Nunito+Sans:300,400,600,700,700i|PT+Sans+Narrow|Source+Sans+Pro:400,600,700');

@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:300,400,700');
@import url('./assets/fonts/worksans/worksansfont.css');
@import url('./assets/fonts/cooperhewitt/cooperhewitt.css');
@import url('https://fonts.googleapis.com/css?family=Cardo:400,400i,700|Rubik:300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Alegreya:400,400i,500,500i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Eczar:400,500,600,700,800');

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue,
    helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  background: #000e19;
  background: hsla(205, 35.8%, 89.4%, 1);
  color: #113249;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sans-serif {
  font-family: 'Cooper Hewitt', 'Helvetica', sans-serif;
  font-weight: 400;
}

.font-reset {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;

  width: 100%;

}

a {
  text-decoration: none;
  color: #fff;
}